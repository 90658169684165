import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { MultiPortfolioReviewContext, type MultiPortfolioReviewContextValue } from './MultiPortfolioReviewContext';
import { MainUploadWrapper } from '../../shared/layout';
import { cloneDeep, isNil, partition } from 'lodash';
import { useMutation } from '@tanstack/react-query';
import {
  type MultiPortfolioParseResult,
  type MultiPortfolioPersistResult,
  type OperationError,
  type OperationResult,
  persistMultiPortfolioUpload,
  type Portfolio,
} from 'venn-api';
import { analyticsService } from 'venn-utils';
import { uploadConfig } from '../../../utils';
import { DataUploaderMode, DataUploaderView } from '../../../types';
import { ConfirmationModalType } from './MultiPortfolioReviewComponents.ConfirmationModal';
import UserContext from '../../../../../contexts/user-context';

const useMutablePortfolios = (parsedData: MultiPortfolioParseResult) => {
  const initialState = useMemo(() => cloneDeep(parsedData.portfolioParseResults), [parsedData.portfolioParseResults]);

  const [parsedResults, setParsedResults] = useState(initialState);

  const updatePortfolio = (index: number, portfolio: Portfolio) => {
    const newParseResults = [...parsedResults];
    newParseResults[index] = {
      ...newParseResults[index],
      parsedPortfolio: portfolio,
    };
    setParsedResults(newParseResults);
  };

  return {
    parsedResults,
    updatePortfolio,
  };
};

const useSavePortfoliosMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess: (data: MultiPortfolioPersistResult) => void;
  onError: (error: Partial<Error & OperationResult<OperationError | undefined>>) => void;
}) => {
  return useMutation(
    async (portfolios: Portfolio[]) => {
      return (
        await persistMultiPortfolioUpload({
          portfolioPersistInputs: portfolios.map((portfolio) => ({
            portfolio,
            writeMode: 'OVERWRITE',
          })),
        })
      ).content;
    },
    { onSuccess, onError },
  );
};

const useMultiPortfolioConfirmationModals = () => {
  const [confirmationModalType, setConfirmationModalType] = useState<ConfirmationModalType>(ConfirmationModalType.None);

  const closeConfirmationModal = useCallback(() => {
    setConfirmationModalType(() => ConfirmationModalType.None);
  }, [setConfirmationModalType]);

  const openDiscardConfirmationModal = useCallback(() => {
    setConfirmationModalType(() => ConfirmationModalType.Discard);
  }, [setConfirmationModalType]);

  const openUploadConfirmationModal = useCallback(() => {
    setConfirmationModalType(() => ConfirmationModalType.Upload);
  }, [setConfirmationModalType]);

  return {
    confirmationModalType,
    closeConfirmationModal,
    openDiscardConfirmationModal,
    openUploadConfirmationModal,
  };
};

export type RootProps = Readonly<{
  parsedData: MultiPortfolioParseResult;
  goBackToUploadStep: () => void;
  goToUploadConfirmation: (persistResult: MultiPortfolioPersistResult) => void;
  children: React.ReactNode;
}>;

export const Root = ({ parsedData, goBackToUploadStep, goToUploadConfirmation, children }: RootProps) => {
  const { confirmationModalType, closeConfirmationModal, openDiscardConfirmationModal, openUploadConfirmationModal } =
    useMultiPortfolioConfirmationModals();

  const { parsedResults, updatePortfolio } = useMutablePortfolios(parsedData);

  useEffect(() => {
    analyticsService.uploadStepViewed({
      dataType: uploadConfig[DataUploaderMode.Portfolios].dataType,
      step: 1,
      stepName: DataUploaderView.Review,
    });
  }, []);

  const [newParsedData, existingParsedData] = partition(
    parsedResults.map((data, index) => {
      return {
        ...data,
        originalIndex: index,
      };
    }),
    (data) => isNil(data.parsedPortfolio.id),
  );

  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const savePortfoliosMutation = useSavePortfoliosMutation({
    onSuccess: (persistResult) => {
      analyticsService.uploadStepCompleted({
        step: 1,
        dataType: uploadConfig[DataUploaderMode.Portfolios].dataType,
        stepName: DataUploaderView.Review,
      });
      goToUploadConfirmation(persistResult);
    },
    onError: (e) => {
      analyticsService.uploadStepFailed({
        step: 1,
        stepName: DataUploaderView.Review,
        dataType: uploadConfig[DataUploaderMode.Portfolios].dataType,
        error: e?.content?.message ?? e?.message,
      });
    },
  });

  const { currentContext } = useContext(UserContext);

  const savePortfolios = useCallback(
    () =>
      savePortfoliosMutation.mutate(
        parsedResults.map((result) => ({ ...result.parsedPortfolio, ownerContextId: currentContext })),
      ),
    [currentContext, parsedResults, savePortfoliosMutation],
  );

  const completeUpload = useCallback(() => {
    if (existingParsedData.length === 0) {
      savePortfolios();
    } else {
      openUploadConfirmationModal();
    }
  }, [existingParsedData.length, openUploadConfirmationModal, savePortfolios]);

  const onBackButtonClick = useCallback(() => {
    if (savePortfoliosMutation.isError) {
      savePortfoliosMutation.reset();
    } else {
      openDiscardConfirmationModal();
    }
  }, [openDiscardConfirmationModal, savePortfoliosMutation]);

  const selectAnotherPortfolio = useCallback(
    (index: number) => {
      setSelectedIndex(index);

      const portfolio = parsedResults[index].parsedPortfolio;
      analyticsService.multiPortfolioUploaderPortfolioChanged({
        portfolioId: portfolio?.id,
        portfolioName: portfolio?.name,
      });
    },
    [parsedResults],
  );
  const context: MultiPortfolioReviewContextValue = useMemo(
    () => ({
      actions: {
        completeUpload,
        onBackButtonClick,
        savePortfolios,
        selectAnotherPortfolio,
        goBackToUploadStep,
      },
      confirmationModals: {
        closeConfirmationModal,
        confirmationModalType,
        openDiscardConfirmationModal,
        openUploadConfirmationModal,
      },
      data: {
        existingParsedData,
        newParsedData,
        parsedResults,
        updatePortfolio,
      },
      savePortfoliosMutation,
      selectedIndexState: {
        selectedIndex,
        setSelectedIndex,
      },
    }),
    [
      closeConfirmationModal,
      completeUpload,
      confirmationModalType,
      existingParsedData,
      newParsedData,
      onBackButtonClick,
      openDiscardConfirmationModal,
      openUploadConfirmationModal,
      parsedResults,
      savePortfolios,
      savePortfoliosMutation,
      selectAnotherPortfolio,
      selectedIndex,
      updatePortfolio,
      goBackToUploadStep,
    ],
  );
  return (
    <MultiPortfolioReviewContext.Provider value={context}>
      <MainUploadWrapper>{children}</MainUploadWrapper>
    </MultiPortfolioReviewContext.Provider>
  );
};
