import React, { useCallback, useMemo } from 'react';
import { type MultiHistoricalPortfolioContextValue } from './MultiHistoricalPortfolioContext';
import { BaseMenu, CheckboxWrapper, DropMenu, GetColor, Icon } from 'venn-ui-kit';
import styled, { css } from 'styled-components';
import moment from 'moment';
import { buttonize, plural } from 'venn-utils';
import type { DropMenuProps } from '../../../../../../../venn-ui-kit/src/components/drop-menu/implementations/BaseDropMenu';
import { BANNER_HEIGHT, DROPDOWN_MENU_WIDTH_PX, SPACING_M, SPACING_S, SPACING_XS } from '../../shared/layout';

type BannerProps = MultiHistoricalPortfolioContextValue;
const AllClear = (
  <>
    <Icon type="check" />
    no errors
  </>
);
const ErrorText = ({ numberOfErrors }: { numberOfErrors: number }) => (
  <>
    <Icon type="triangle-exclamation" />
    {plural(numberOfErrors, {
      1: '{{count}} date in this portfolio contains errors',
      other: '{{count}} dates in this portfolio contains errors',
    })}
  </>
);
export const Banner = ({ selectedDate, selectDate, allDates, errors }: BannerProps) => {
  const [isFilteredToErroredDates, setIsFilteredToErroredDates] = React.useState(false);
  const numberOfErrors = Object.keys(errors).length;
  const hasErrors = numberOfErrors > 0;
  const filteredDates = useMemo(
    () => (isFilteredToErroredDates ? allDates.filter((date) => errors[date.valueOf()]) : allDates),
    [allDates, errors, isFilteredToErroredDates],
  );
  const dropdownItems = useMemo(
    () => filteredDates.map((date) => ({ value: date, label: moment.utc(date).format('DD MMMM YYYY') })),
    [filteredDates],
  );
  const menuComponent: DropMenuProps<Date>['menuComponent'] = useCallback(
    (highlighted, forceCollapse, className) => (
      <MenuPopup className={className}>
        {hasErrors && (
          <StyledCheckboxWrapper
            justify="left"
            alignItems="center"
            checked={isFilteredToErroredDates}
            onChange={() => setIsFilteredToErroredDates((prev) => !prev)}
          >
            <DateFilterContent>
              <RedIcon type="triangle-exclamation" />
              Show only dates with errors
            </DateFilterContent>
          </StyledCheckboxWrapper>
        )}
        <StyledBaseMenu
          items={dropdownItems}
          itemsContainerCss={css`
            padding: 0;
          `}
          renderItem={({ value, label }) => (
            <DateItem
              key={label}
              {...buttonize((e) => {
                e.stopPropagation();
                selectDate(value);
                forceCollapse();
              })}
              isHighlighted={highlighted ? highlighted.value === value : false}
            >
              {label}
              {errors[value.valueOf()] && <RedIcon type="triangle-exclamation" />}
            </DateItem>
          )}
        />
      </MenuPopup>
    ),
    [dropdownItems, errors, hasErrors, isFilteredToErroredDates, selectDate],
  );
  return (
    <Container hasErrors={hasErrors}>
      <Left>
        <DatePickerText>As of date</DatePickerText>
        <DropMenu
          items={dropdownItems}
          selected={selectedDate}
          menuComponent={menuComponent}
          width={DROPDOWN_MENU_WIDTH_PX}
          minimumItemsToTrigger={0}
        />
      </Left>
      <Right>{hasErrors ? ErrorText({ numberOfErrors }) : AllClear}</Right>
    </Container>
  );
};

const StyledCheckboxWrapper = styled(CheckboxWrapper)`
  border-bottom: 1px solid ${GetColor.PaleGrey};
  border-radius: 0;
  padding: ${SPACING_S} ${SPACING_M};
`;
const RedIcon = styled(Icon)`
  color: ${GetColor.Error};
`;
const DateFilterContent = styled.div`
  gap: ${SPACING_XS};
  display: inline-flex;
`;

const MenuPopup = styled.div`
  color: ${GetColor.Black};
  background-color: ${GetColor.White};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
`;

const StyledBaseMenu = styled(BaseMenu<Date>)`
  box-shadow: none;
`;

const Container = styled.div<{ hasErrors: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ hasErrors }) => (hasErrors ? GetColor.Error : GetColor.Green)};
  padding: ${SPACING_S} ${SPACING_M};
  color: ${GetColor.White};
  height: ${BANNER_HEIGHT};
`;

const Left = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${SPACING_S};
`;

const DatePickerText = styled.div`
  display: inline-flex;
  align-items: center;
`;

const Right = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: ${SPACING_S};
`;
const DateItem = styled.div<{ isHighlighted: boolean }>`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ isHighlighted }) => (isHighlighted ? GetColor.PaleGrey : 'inherit')};
  padding: ${SPACING_S} ${SPACING_M};
  &:hover {
    background-color: ${GetColor.PaleGrey};
  }
`;
