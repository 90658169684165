import styled from 'styled-components';
import React, { useContext, useMemo } from 'react';
import { Flexbox, getAppTitle, GetColor, getTextThemeProvider, HyperLink, Icon, Loading } from 'venn-ui-kit';
import { MultiPortfolioReviewContext } from './MultiPortfolioReviewContext';

type ContentProps = {
  children: React.ReactNode;
};
export const Content = ({ children }: ContentProps) => {
  const { savePortfoliosMutation } = useContext(MultiPortfolioReviewContext);
  const innerContent = useMemo(() => {
    if (savePortfoliosMutation.isLoading) {
      return (
        <Flexbox direction="row" grow={1} alignItems="center" justifyContent="center">
          <Loading title="Uploading portfolio data to Data Library" />
        </Flexbox>
      );
    }

    if (savePortfoliosMutation.isError) {
      return (
        <Flexbox direction="row" grow={1} alignItems="center" justifyContent="center">
          <Flexbox direction="column" gap={20} alignItems="center">
            <ErrorIcon type="file-exclamation" />
            <ErrorContent>
              Data failed to upload due to a system error.
              <br />
              Please contact{' '}
              <HyperLink href={`mailto:${getTextThemeProvider().supportEmail}`}>
                {`${getAppTitle()} support`}
              </HyperLink>{' '}
              for more information
            </ErrorContent>
          </Flexbox>
        </Flexbox>
      );
    }
    return children;
  }, [children, savePortfoliosMutation.isError, savePortfoliosMutation.isLoading]);
  return <ContentContainer>{innerContent}</ContentContainer>;
};

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  align-self: stretch;
  flex: 1;
  overflow: hidden;
`;
const ErrorIcon = styled(Icon)`
  font-size: 42px;
  color: ${GetColor.Error};
`;

const ErrorContent = styled.div`
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.35;
`;
