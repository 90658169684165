import React, { useCallback, useState, useEffect } from 'react';
import {
  analysisViewIdState,
  type DateRangeInputId,
  dateRangeInputConsistencyState,
  dateRangeInputDateRangeState,
  dateRangeInputNameState,
  dateRangeInputsState,
  DEFAULT_CONSISTENCY,
  DEFAULT_DATE_RANGE,
  getDefaultRange,
  fullGlobalDateRange,
  getNewDateRangeInputId,
  isReportState,
  nextDateRangeInputNameState,
  useCachedLoadableValue,
  primaryFactorLens,
} from 'venn-state';
import styled from 'styled-components';
import {
  type DateRange,
  type RangeType,
  DateRangePickerContent,
  useDateRangePickerContent,
  Label,
  RadioGroup,
  Icon,
  GetColor,
  Tooltip,
  Warning,
  ANALYSIS_PERIOD_HELP_HREF,
} from 'venn-ui-kit';
import { useRecoilValue, constSelector, useRecoilCallback, waitForAll } from 'recoil';
import {
  type ComputedDateRange,
  toEndOfPeriod,
  analyticsService,
  AnalyticsUtils,
  FREQUENCY_DATEPICKER_MODES,
  withSuspense,
  intersectRanges,
  orderFromTo,
} from 'venn-utils';
import { InputEditor, InputEditorLoadState } from './InputEditor';

const EDITOR_MAX_HEIGHT = 640;

interface DateRangeInputEditorProps {
  /** The inputId to edit, or undefined if a new input should be created. */
  inputId: DateRangeInputId | undefined;
  /** Called with the saved input ID if the save was completed, or undefined if the user quit without saving. */
  onClose: (savedInputId?: DateRangeInputId) => void;
}

/** Modal for editing an existing date range input or creating a new one. */
export const DateRangeInputEditor = React.memo(
  withSuspense(<InputEditorLoadState maxHeight={EDITOR_MAX_HEIGHT / 2} />, DateRangeInputEditorInternal),
);

function DateRangeInputEditorInternal({ inputId: initialInputId, onClose }: DateRangeInputEditorProps) {
  const factorLens = useCachedLoadableValue(primaryFactorLens);
  const nextInputName = useRecoilValue(nextDateRangeInputNameState);
  const originalName = useRecoilValue(
    initialInputId ? dateRangeInputNameState(initialInputId) : constSelector(nextInputName),
  );
  const [tempName, setTempName] = useState(originalName);
  useEffect(() => setTempName(originalName), [originalName]);

  const [tempConsistency, setTempConsistency] = useState(
    useRecoilValue(
      initialInputId ? dateRangeInputConsistencyState(initialInputId) : constSelector(DEFAULT_CONSISTENCY),
    ),
  );

  const [tempDateRange, setTempDateRange] = useState(
    useRecoilValue(initialInputId ? dateRangeInputDateRangeState(initialInputId) : constSelector(undefined)),
  );

  const globalRange = useRecoilValue<ComputedDateRange>(fullGlobalDateRange);

  const computedRange = tempConsistency === 'BLOCK' ? getDefaultRange() : globalRange;
  const frequency = computedRange?.frequency ?? 'DAILY';

  const granularity = computedRange.frequency ? FREQUENCY_DATEPICKER_MODES[computedRange.frequency] : 'day';

  // TODO(collin): extract to callback in dateInputRange recoil file for reusability
  const onSaveChanges = useRecoilCallback(
    ({ set, snapshot }) =>
      () => {
        const finalInputId = initialInputId ?? getNewDateRangeInputId();
        const finalDateRange = orderFromTo(tempDateRange || DEFAULT_DATE_RANGE);

        snapshot.getPromise(waitForAll([isReportState, analysisViewIdState])).then(([isReport, viewId]) => {
          const trackingProps = {
            name: tempName || originalName,
            range: AnalyticsUtils.rangeFormat(finalDateRange.period),
            startDate: finalDateRange.from ? AnalyticsUtils.dateFormat(finalDateRange.from) : undefined,
            endDate: finalDateRange.to ? AnalyticsUtils.dateFormat(finalDateRange.to) : undefined,
            consistency: tempConsistency,
            sourcePage: isReport ? 'REPORT_LAB' : 'STUDIO',
            viewId,
          };
          if (initialInputId) {
            analyticsService.inputsDateRangeGroupModified(trackingProps);
          } else {
            analyticsService.inputsDateRangeGroupCreated(trackingProps);
          }
        });

        set(dateRangeInputNameState(finalInputId), tempName || originalName);
        set(dateRangeInputConsistencyState(finalInputId), tempConsistency);
        set(dateRangeInputDateRangeState(finalInputId), finalDateRange);
        if (!initialInputId) {
          set(dateRangeInputsState, (existingIds) => [...existingIds, finalInputId]);
        }

        onClose(finalInputId);
      },
    [initialInputId, onClose, originalName, tempConsistency, tempDateRange, tempName],
  );

  const onQuit = useCallback(() => {
    // TODO(will, collin): IMV2 onQuit should show an unsaved changes modal when groupId is defined and changes are made.
    // Modal should only show if the subject group is actually used somewhere OR is >3 subjects long. Use a useRecoilCallback to do this
    // combined with modal state and <ConfirmationModal ../>
    onClose(undefined);
  }, [onClose]);

  const { computedMaxRange, computedValue } = useDateRangePickerContent(
    computedRange.maxRange,
    granularity,
    tempDateRange,
    frequency,
    factorLens,
  );

  const onUpdatePeriod = useCallback((rangeType: RangeType) => {
    setTempDateRange({ period: rangeType });
  }, []);

  const onConsistencyChange = useCallback(
    (consistency) => {
      if (consistency === 'GLOBAL') {
        // Clamp range to the global available range
        setTempDateRange(
          (current) =>
            current && {
              period: current.period,
              ...intersectRanges(current, globalRange.maxRange),
            },
        );
      }
      setTempConsistency(consistency);
    },
    [globalRange.maxRange],
  );

  const onPickerChange = useCallback(
    (range: DateRange) => {
      // Align the dates to end of period to be consistent with the BE
      setTempDateRange({
        ...range,
        to: toEndOfPeriod(range.to, granularity),
        from: toEndOfPeriod(range.from, granularity),
      });
    },
    [granularity],
  );

  return (
    <InputEditor
      title={
        <span>
          Date Ranges{' '}
          <Tooltip content="Click here to learn more about Date Ranges">
            <a style={{ color: 'unset' }} target="_blank" rel="noopener noreferrer" href={ANALYSIS_PERIOD_HELP_HREF}>
              <Icon type="circle-question" />
            </a>
          </Tooltip>
        </span>
      }
      name={tempName}
      setName={setTempName}
      onClose={onQuit}
      onSave={onSaveChanges}
      isNew={!initialInputId}
      maxHeight={EDITOR_MAX_HEIGHT}
    >
      <BodyContainer>
        {computedMaxRange ? (
          <DateRangePickerContent
            period={computedValue?.period}
            maxFrequency={frequency}
            value={computedValue}
            maxRange={computedMaxRange}
            onApply={onPickerChange}
            onUpdatePeriod={onUpdatePeriod}
            onUpdateDate={onPickerChange}
            layout="left"
            granularity={granularity}
            hideActions
            autoFocus={false}
            factorLens={factorLens}
          />
        ) : (
          <div style={{ margin: '20px 0' }}>
            <Warning
              text="The Date Range Setting “Use Consistent Dates…” requires a common overlap between all subjects in the view. Select the “Use Individual Dates…” Date Range Setting, or change the subjects in this view to resolve"
              large
            />
          </div>
        )}

        <div style={{ alignSelf: 'flex-start' }}>
          <Label>
            Date Range Setting
            <Tooltip content={CONSISTENCY_TOOLTIP}>
              <InfoIcon type="info-circle" />
            </Tooltip>
          </Label>
          <RadioGroupContainer>
            <RadioGroup
              groupName="consistency"
              options={[
                { label: 'Use Consistent Dates across all Assigned Blocks', value: 'GLOBAL' },
                { label: 'Use Individual Dates on Each Assigned Block', value: 'BLOCK' },
              ]}
              defaultOption={tempConsistency}
              onChange={onConsistencyChange}
            />
          </RadioGroupContainer>
        </div>
      </BodyContainer>
    </InputEditor>
  );
}

const RadioGroupContainer = styled.div`
  margin-top: 6px;
  flex-direction: column;
  display: flex;
  label {
    min-height: 26px;
  }
`;

const InfoIcon = styled(Icon)`
  color: ${GetColor.Primary.Dark};
  margin-left: 4px;
  font-size: 14px;
`;

const BodyContainer = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  padding: 0 20px 10px 20px;
  width: 100%;
  > :first-child {
    width: 100%;
  }
`;

const CONSISTENCY_TOOLTIP =
  '“Consistent Date” option assigns a common date range to all blocks. “Individual Dates” configures the date range for each block based on the available history of the subjects being used.';
