import React from 'react';
import { noop } from 'lodash';
import type {
  MultiPortfolioPersistResult,
  OperationError,
  OperationResult,
  Portfolio,
  PortfolioParseResult,
} from 'venn-api';
import type { UseMutationResult } from '@tanstack/react-query';

enum ConfirmationModalType {
  None,
  Discard,
  Upload,
}
type PortfolioParseResultWithOriginalIndex = PortfolioParseResult & {
  originalIndex: number;
};
export type MultiPortfolioReviewContextValue = {
  confirmationModals: {
    confirmationModalType: ConfirmationModalType;
    closeConfirmationModal: () => void;
    openDiscardConfirmationModal: () => void;
    openUploadConfirmationModal: () => void;
  };
  data: {
    parsedResults: PortfolioParseResult[];
    updatePortfolio: (index: number, portfolio: Portfolio) => void;
    newParsedData: PortfolioParseResultWithOriginalIndex[];
    existingParsedData: PortfolioParseResultWithOriginalIndex[];
  };
  selectedIndexState: {
    selectedIndex: number;
    setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
  };
  savePortfoliosMutation: UseMutationResult<
    MultiPortfolioPersistResult,
    Partial<Error & OperationResult<OperationError | undefined>>,
    Portfolio[],
    unknown
  >;
  actions: {
    savePortfolios: () => void;
    completeUpload: () => void;
    onBackButtonClick: () => void;
    selectAnotherPortfolio: (index: number) => void;
    goBackToUploadStep: () => void;
  };
};

const defaultMultiPortfolioReviewContextValue: MultiPortfolioReviewContextValue = {
  actions: {
    completeUpload: noop,
    onBackButtonClick: noop,
    savePortfolios: noop,
    selectAnotherPortfolio: noop,
    goBackToUploadStep: noop,
  },
  confirmationModals: {
    closeConfirmationModal: noop,
    confirmationModalType: ConfirmationModalType.None,
    openDiscardConfirmationModal: noop,
    openUploadConfirmationModal: noop,
  },
  data: {
    existingParsedData: [],
    newParsedData: [],
    parsedResults: [],
    updatePortfolio: noop,
  },
  savePortfoliosMutation: undefined as unknown as UseMutationResult<
    MultiPortfolioPersistResult,
    Partial<Error & OperationResult<OperationError | undefined>>,
    Portfolio[],
    unknown
  >,
  selectedIndexState: {
    selectedIndex: 0,
    setSelectedIndex: noop,
  },
};

export const MultiPortfolioReviewContext = React.createContext<MultiPortfolioReviewContextValue>(
  defaultMultiPortfolioReviewContextValue,
);
MultiPortfolioReviewContext.displayName = 'MultiPortfolioReviewContext';
