import type { AccountTierEnum, TrialStatusEnum } from 'venn-api';
import mockPortfolio from './portfolio';
import { mockHistoricalPortfolio1, mockHistoricalPortfolio2 } from './historicalPortfolio';
import mockCorrelationPortfolio from './Correlation/PortfolioCorrelation';
import mockCorrelationPortfolioResponse from './Correlation/PortfolioCorrelationResponse';
import mockReturnsGridResponse from './ReturnsGrid/PortfolioReturnsGridResponse';
import mockFactorSummary from './FactorSummary/FactorSummaryData';
import mockFactorDescriptions from './FactorSummary/FactorDescriptionsData';
import mockFactorTrendReturn from './FactorTrend/FactorTrendReturnData';
import mockInterpolationOptions from './Proxy/InterpolationData';
import mockDesmoothOptions from './Proxy/DesmoothData';
import mockExtrapolatedProxyMetrics from './Proxy/ExtrapolatedProxyMetrics';
import mockMembers from './Organization/MembersData';
import mockOrganizations from './Organization/Organizations';
import mockRiskStatistics from './RiskStatistics/RiskStatisticsData';
import mockNotablePeriodsResponse from './NotablePeriods/NotablePeriods';
import mockReturnsDistribution from './ReturnsDistributionAnalysis/ReturnsDistributionData';
import mockRollingData from './RollingAnalysis/RollingData';
import mockMetadataOptions from './DataUploader/MappingData';
import mockUploadedFund from './DataUploader/UploadFileData';
import mockPersistResponse from './DataUploader/PersistData';
import mockSearchInvestmentsResponse from './Search/SearchInvestmentsData';
import mockSearchStudioViews from './Search/SearchStudioViewsData';
import mockDrawdownInvestmentsContributions from './DrawdownAnalysis/PortfolioInvestmentsData';
import {
  factorResponse as mockFactorsResponse,
  singleFactorResponse as mockSingleFactorResponse,
} from './FactorLenses/FactorLensesResponse';
import {
  factorLenses as mockFactorLenses,
  factorLensesContext as mockFactorLensesContext,
  notablePeriods as mockNotablePeriods,
} from './FactorLenses/factor-lenses';
import mockProfile from './profile';
import mockFactorPerformance from './factor-performance';
import mockInvestments from './investments';
import mockFundWithNoReturns from './fundWithNoReturns';
import mockRecentUserActivity from './recent-user-activity';
import mockTemplates from './templates';
import mockSavedViews, { mockView } from './savedViews';
import mockRecentViews, { mockRecentViewsWithSavedBaseline } from './recentViews';
import mockUserSettings from './user-settings';
import mockSearchOptions from './searchOptions';
import mockSnapshots, { mockSnapshotMetaData, mockUploadMetaData } from './Snapshots/snapshots';
import { mockScheduledLogs } from './Scheduled/scheduledLogs';
import mockSponsoredAccountResults from './Sponsor/SponsoredAccountsResults';
import noop from 'lodash/noop';
import { asyncNoop } from '../../utils';

const mockProProfile = {
  ...mockProfile,
  organization: {
    ...mockProfile.organization,
    accountTier: 'PRO' as AccountTierEnum,
    trial: {
      ...mockProfile.organization.trial,
      status: 'COMPLETED' as TrialStatusEnum,
    },
  },
};

const mockProUserContext = {
  profileSettings: mockProProfile,
  settings: {
    system: {},
    user: {},
  },
  organizations: [],
  error: undefined,
  loading: false,
  refresh: noop,
  updateSettings: noop,
  hasCompletedAnUpload: false,
  hasOrgCompleteAnUpload: false,
  setUserCompletedUpload: noop,
  hasPermission: () => true,
  debugForcePermission: () => {},
  hasDebugOverride: () => false,
  hasPermissionForResource: () => true,
  canWriteToPortfolio: () => true,
  currentContext: 'globalId',
  setCurrentContext: noop,
  saveCustomColors: asyncNoop,
};

export {
  mockFactorLenses,
  mockFactorLensesContext,
  mockNotablePeriods,
  mockFactorPerformance,
  mockFundWithNoReturns,
  mockPortfolio,
  mockHistoricalPortfolio1,
  mockHistoricalPortfolio2,
  mockCorrelationPortfolio,
  mockCorrelationPortfolioResponse,
  mockReturnsGridResponse,
  mockFactorSummary,
  mockFactorDescriptions,
  mockFactorTrendReturn,
  mockInterpolationOptions,
  mockDesmoothOptions,
  mockExtrapolatedProxyMetrics,
  mockMembers,
  mockOrganizations,
  mockRiskStatistics,
  mockNotablePeriodsResponse,
  mockReturnsDistribution,
  mockRollingData,
  mockMetadataOptions,
  mockUploadedFund,
  mockPersistResponse,
  mockDrawdownInvestmentsContributions,
  mockFactorsResponse,
  mockSingleFactorResponse,
  mockSearchInvestmentsResponse,
  mockSearchStudioViews,
  mockProfile as mockFreeProfile,
  mockProfile,
  mockProProfile,
  mockRecentUserActivity,
  mockInvestments,
  mockUserSettings,
  mockSearchOptions,
  mockProUserContext,
  mockTemplates,
  mockSavedViews,
  mockView,
  mockRecentViews,
  mockRecentViewsWithSavedBaseline,
  mockSnapshots,
  mockSnapshotMetaData,
  mockUploadMetaData,
  mockSponsoredAccountResults,
  mockScheduledLogs,
};
export * from './build-portfolio';
export { default as mockHistorical } from './DrawdownAnalysis/historical';
export { default as mockMetaData } from './MetaData/MedaData';
export * from './analysis';
export * from './autocomplete';
export * from './forecasts';
export * from './upload';
export * from './subject';
export * from './authentication';
export * from './library-search';
export * from './Contributions/ContributionData';
export * from './Benchmarks/BenchmarksData';
export * from './CumulativeAnalysis/CumulativeData';
export * from './Attribution/AttributionData';
export * from './portfolio';
export * from './analysisView';
export * from './rangeDebugGroup';
export * from './theme/mockTheme';
export { default as mockFund } from './fund';
export * from './integratedFund';
export * from './residualOverrides';
export * from './analysis-view-search';
export * from './factor-breakdown';
export * from './login-history';
export * from './mockStudioApi';
export * from './ReturnsGrid/CalendarReturnsBlockMocks';
export * from './Holdings';
export * from './studioSubjects';
export * from './privates';
export * from './PeerGroups/peerGroups';
export * from './GrowthSimulation/growthSimulation';
export * from './cashflowSettings';
