import type { ActionEnum, Portfolio, PrivatePortfolioNode } from 'venn-api';
import type { ReactNode } from 'react';
import type { FeatureName } from 'venn-utils';

export const APPEND_ONLY = 'Append Only';
export const DO_NOT_MAP = 'Do Not Map';
export const FUND = 'Fund';

export enum AppendType {
  OVERWRITE_ALL = 1,
  OVERWRITE_OVERLAPPING,
  APPEND,
}

export enum DataUploaderMode {
  Returns,
  NewNavs,
  Initial,
  Privates,
  Portfolios,
}

// assign string values just so that number values won't incorrectly be used as the step number
export enum DataUploaderView {
  Upload = 'Upload',
  Review = 'Review',
  DisplayNavMapping = 'DisplayNavMapping',
  Initial = 'Initial',
}

export interface DataCell {
  // 0-indexed
  row?: number;
  column: number;
  value: string;
}

export interface DataPoint {
  x: number;
  y: number;
}

export const DO_NOT_MAP_ID = 1;

export type PortfolioMultiUploaderProps = {
  closeUploaderSlideout: () => void;
  lastTimeBeforeUpdate?: number;
  onPrivatePortfolioCreated: (portfolio: PrivatePortfolioNode) => void;
};

export type MultiUploaderReviewViewId = 'REVIEW_RETURNS' | 'REVIEW_PRIVATES';
export type MultiUploaderUploadViewId = 'UPLOAD_RETURNS' | 'UPLOAD_PRIVATES';
type MultiUploaderChooseActionView = 'CHOOSE_ACTION' | 'CHOOSE_ACTION_PORTFOLIO';

export type MultiPortfolioUploaderViewId =
  | 'UPLOAD_PORTFOLIO'
  | 'REVIEW_PRO_FORMA_PORTFOLIO'
  | 'PORTFOLIO_UPLOAD_CONFIRMATION'
  | 'REVIEW_HISTORICAL_PORTFOLIO';

type PortfolioMultiUploaderViewId =
  | MultiUploaderReviewViewId
  | MultiUploaderUploadViewId
  | MultiUploaderChooseActionView
  | MultiPortfolioUploaderViewId
  | 'DUPLICATE_PORTFOLIO'
  | 'NEW_PORTFOLIO'
  | 'CREATE_PRIVATE_PORTFOLIO'
  | 'CREATE_MASTER_PORTFOLIO';

export interface PortfolioMultiUploaderView {
  requiredPermissions: ActionEnum[];
  requiredFeatures: FeatureName[];
  id: PortfolioMultiUploaderViewId;
  title: string;
  subtitle?: ReactNode | (() => ReactNode);
  description: string | undefined;
  highlightText: string | undefined;
}

export type PortfolioAccessMode = 'CREATE_NEW' | 'OVERWRITE_EXISTING';

export type UploadPortfolioAllocatorProps = {
  portfolio: Portfolio;
  selectedIndex: number;
  updatePortfolio: (index: number, portfolio: Portfolio) => void;
  accessMode: PortfolioAccessMode;
};

export type RowData<T> = {
  path: number[];
  node: T;
  root: T;
};
