import { Banner } from './MultiHistoricalReviewComponents.RightPane.Banner';
import { ContentContainer } from './MultiHistoricalReviewComponents.RightPane.ContentContainer';
import { Root } from './MultiHistoricalReviewComponents.RightPane.Root';

export const MultiHistoricalReviewComponents = {
  RightPane: {
    Root,
    Banner,
    ContentContainer,
  },
};
