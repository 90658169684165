import styled from 'styled-components';
import FaqLink from '../../../../faq-link/FaqLink';
import { GetColor } from 'venn-ui-kit';

export const UNIVERSAL_UPLOADER_WIDTH = 1062;

export const MainViewContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
  flex: 1;
  align-self: stretch;
  overflow: hidden;
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 24px 0 0 24px;
  max-width: 600px;
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const RightContainer = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
`;

export const MainUploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  overflow: hidden;
`;

export const UploadContent = styled.div`
  margin: 0 20px 0 15px;
  flex: 1;
`;

export const MainUploadRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  flex: 1;
  align-self: stretch;
`;

export const UploadSideBar = styled.div`
  width: 180px;
`;

export const StyledFaqLink = styled(FaqLink)`
  color: ${GetColor.DarkBlue};
  font-weight: normal;
`;

export const UPLOAD_LEFT_CONTAINER_WIDTH = 280;
export const SPACING_XS = '4px';
export const SPACING_S = '8px';
export const SPACING_M = '20px';
export const BANNER_HEIGHT = '50px';
export const DROPDOWN_MENU_WIDTH_PX = 280;
