import { isEmpty } from 'lodash';
import React, { useContext } from 'react';
import { type IndexedPortfolioParseResult, ParsedPortfolioOption } from '../../review/ParsedPortfolioOption';
import { ScrollableSectionWithStickyHeader } from '../../review/ScrollableSectionWithStickyHeader';
import styled from 'styled-components';
import { UPLOAD_LEFT_CONTAINER_WIDTH } from '../../shared/layout';
import { GetColor } from 'venn-ui-kit';
import { MultiPortfolioReviewContext } from './MultiPortfolioReviewContext';

export const LeftSideBar = () => {
  const {
    data: { existingParsedData, newParsedData },
    actions: { selectAnotherPortfolio },
    selectedIndexState: { selectedIndex },
  } = useContext(MultiPortfolioReviewContext);
  const renderLeftSidebarSection = (
    title: string,
    parsedResults: IndexedPortfolioParseResult[],
    index: number,
    reverseIndex: number,
  ) => {
    if (isEmpty(parsedResults)) {
      return null;
    }

    const isSelected = (index: number) =>
      index >= 0 && index < parsedResults.length && parsedResults[index].originalIndex === selectedIndex;

    return (
      <ScrollableSectionWithStickyHeader
        groupIndex={index}
        groupReverseIndex={reverseIndex}
        title={title}
        count={parsedResults.length}
      >
        {parsedResults.map((data, resultIndex) => (
          <ParsedPortfolioOption
            setSelectedIndex={selectAnotherPortfolio}
            isNextItemSelected={isSelected((resultIndex + 1) % parsedResults.length)}
            selected={isSelected(resultIndex)}
            data={data}
            key={data.originalIndex}
          />
        ))}
      </ScrollableSectionWithStickyHeader>
    );
  };
  return (
    <LeftContainer>
      {renderLeftSidebarSection('EXISTING PORTFOLIOS', existingParsedData, 0, isEmpty(newParsedData) ? 0 : 1)}
      {renderLeftSidebarSection('NEW PORTFOLIOS', newParsedData, isEmpty(existingParsedData) ? 0 : 1, 0)}
    </LeftContainer>
  );
};

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${UPLOAD_LEFT_CONTAINER_WIDTH}px;
  overflow: auto;
  align-items: flex-start;
  align-self: stretch;
  border-right: 1px solid ${GetColor.GreyScale.Grey30};
`;
