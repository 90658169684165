import type { Dispatch, SetStateAction } from 'react';
import React, { useCallback, useState } from 'react';
import { AccordionMenu, Body1, EmptyStateIcon, Headline3, Hint, MenuActions, SkeletalDropMenu } from 'venn-ui-kit';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { librarySearchParamsAtom, savedFiltersSelector } from 'venn-state';
import { FilterMenuTrigger } from './shared';
import styled from 'styled-components';
import type { StoredSearch } from 'venn-api';
import { getSavedSearch } from 'venn-api';
import ManageFilterMenuItemRenderer from './ManageFilterMenuItemRenderer';
import { noop } from 'lodash';
import { getLibrarySearchParamsFromSearchQuery } from '../search';
import { logExceptionIntoSentry } from 'venn-utils';

interface ManageFilterProps {
  /** The text on the trigger to open/close the filter */
  label: string;
  /** Class name to pass through to the drop menu component */
  className?: string;
}

interface MenuComponentProps {
  onClose: () => void;
  loadFilter: (arg0: string) => void;
}

interface MenuActionsComponentProps extends MenuComponentProps {
  disabled?: boolean;
  selectedSavedFilter: string | undefined;
}

interface AccordionProps {
  selectedSavedFilter: string | undefined;
  setSelectedSavedFilter: Dispatch<SetStateAction<string | undefined>>;
}
const AccordionMenuComponent = ({ selectedSavedFilter, setSelectedSavedFilter }: AccordionProps) => {
  const savedFilters = useRecoilValue(savedFiltersSelector);

  const menuOptions = savedFilters.map((item) => ({
    value: item,
    label: item.name,
    items: [],
  }));

  return (
    <StyledAccordionMenu>
      {menuOptions.length === 0 ? (
        <Wrapper>
          <EmptyStateIcon />
          <Headline3>No items found</Headline3>
          <Hint data-testid="qa-empty-state">Filters will appear here after you save them.</Hint>
        </Wrapper>
      ) : (
        <AccordionMenu
          expanded
          items={menuOptions}
          selectedId={selectedSavedFilter}
          idGetter={(value: StoredSearch) => value.id}
          onSelect={(value: StoredSearch) => {
            setSelectedSavedFilter(value.id);
          }}
          ItemRenderer={ManageFilterMenuItemRenderer}
          className="qa-accordion-menu"
          label="top-level-menu"
        />
      )}
    </StyledAccordionMenu>
  );
};

const MenuActionsComponent = ({ onClose, loadFilter, disabled, selectedSavedFilter }: MenuActionsComponentProps) => {
  return (
    <StyledMenuActions
      disabled={disabled || !selectedSavedFilter}
      onCancel={onClose}
      onApply={() => {
        loadFilter(selectedSavedFilter!);
        onClose();
      }}
      applyLabel="LOAD"
    />
  );
};

const MenuComponent = ({ onClose, loadFilter }: MenuComponentProps) => {
  const [selectedSavedFilter, setSelectedSavedFilter] = useState<string>();
  return (
    <PopupMenuWrapper>
      <Header> Manage Saved Filters</Header>
      <React.Suspense fallback={<StyledMenuActions onCancel={onClose} onApply={noop} disabled />}>
        <AccordionMenuComponent
          selectedSavedFilter={selectedSavedFilter}
          setSelectedSavedFilter={setSelectedSavedFilter}
        />
        <MenuActionsComponent onClose={onClose} loadFilter={loadFilter} selectedSavedFilter={selectedSavedFilter} />
      </React.Suspense>
    </PopupMenuWrapper>
  );
};

export const useLoadSavedFilters = () => {
  const setLibrarySearchParams = useSetRecoilState(librarySearchParamsAtom);
  return useCallback(
    (savedFilter: string) => {
      getSavedSearch(savedFilter)
        .then((result) => setLibrarySearchParams(getLibrarySearchParamsFromSearchQuery(result.content)))
        .catch((e) => {
          logExceptionIntoSentry(e);
        });
    },
    [setLibrarySearchParams],
  );
};

/**
 * Component for a Manage Saved Filters panel, which includes a trigger button and an accordion menu
 */
const ManageSavedFilters = ({ label, className }: ManageFilterProps) => {
  const loadSavedFilters = useLoadSavedFilters();
  return (
    <SkeletalDropMenu
      menuPosition="center"
      className={className}
      triggerComponent={(expanded, toggleMenu) => (
        <FilterMenuTrigger
          icon="save"
          label={label}
          isOpen={expanded}
          onClick={toggleMenu}
          aria-expanded={expanded}
          aria-haspopup
        />
      )}
      menuComponent={(applyStateAndClose, onClose) => <MenuComponent onClose={onClose} loadFilter={loadSavedFilters} />}
      disabled={false}
    />
  );
};

const PopupMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
`;

const StyledMenuActions = styled(MenuActions)`
  width: 100%;
  margin: 0 0;
  padding-left: 15px;
`;

const StyledAccordionMenu = styled.div`
  padding-top: 20px;
  max-height: 500px;
  overflow: scroll;

  .caret-button {
    margin-top: 6px;
  }
`;

const Header = styled(Body1)`
  padding-top: 14px;
  padding-left: 20px;
  font-weight: bold;
`;

const Wrapper = styled.div`
  text-align: center;
  font-weight: bold;
  margin: 0 4px 0 12px;
  h3 {
    text-align: center;
  }
`;

export default React.memo(ManageSavedFilters);
