import React, { useContext } from 'react';
import { MultiPortfolioReviewContext } from '../common/MultiPortfolioReviewContext';
import type { Portfolio } from 'venn-api';

type ContentContainerProps = {
  children: (props: { portfolio: Portfolio }) => React.ReactNode;
};
export const ContentContainer = ({ children }: ContentContainerProps) => {
  const {
    data: { parsedResults },
    selectedIndexState: { selectedIndex },
  } = useContext(MultiPortfolioReviewContext);
  return <>{children({ portfolio: parsedResults[selectedIndex].parsedPortfolio })}</>;
};
