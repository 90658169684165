import { UniversalUploaderFooter } from '../../../components/page-parts/UniversalUploaderFooter';
import React, { useContext } from 'react';
import { MultiPortfolioReviewContext } from './MultiPortfolioReviewContext';
import { hasUnmatchedFunds } from '../../review/helpers';

const NEEDS_MAPPING_TOOLTIP_MESSAGE =
  'Some portfolios contain investments that are not mapped to investments in the Data Library. Please ensure all investments are mapped in order to continue.';

export const Footer = () => {
  const {
    actions: { onBackButtonClick, completeUpload },
    data: { parsedResults },
    savePortfoliosMutation,
  } = useContext(MultiPortfolioReviewContext);
  const anyPortfolioHasUnmatchedFunds = parsedResults.some((parsedResult) =>
    hasUnmatchedFunds(parsedResult.parsedPortfolio),
  );

  return (
    <UniversalUploaderFooter
      noMargin
      onCancel={onBackButtonClick}
      onContinue={completeUpload}
      primaryLabel="Complete Upload"
      disabled={!savePortfoliosMutation.isIdle || anyPortfolioHasUnmatchedFunds}
      primaryTooltip={anyPortfolioHasUnmatchedFunds ? NEEDS_MAPPING_TOOLTIP_MESSAGE : undefined}
    />
  );
};
