import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  type HistoricalPortfolioErrorState,
  MultiHistoricalPortfolioContext,
  type MultiHistoricalPortfolioContextValue,
} from './MultiHistoricalPortfolioContext';
import { identity, isEmpty, isNil, sortBy, uniq, uniqBy } from 'lodash';
import { MultiPortfolioReviewContext } from '../common/MultiPortfolioReviewContext';
import type { Portfolio } from 'venn-api';
import moment from 'moment';

type RootProps = {
  children: (props: MultiHistoricalPortfolioContextValue) => React.ReactNode;
};
export const Root = ({ children }: RootProps) => {
  const {
    data: { parsedResults },
    selectedIndexState: { selectedIndex },
  } = useContext(MultiPortfolioReviewContext);
  const openedPortfolio = parsedResults[selectedIndex].parsedPortfolio;
  const allDates = sortBy(
    uniq(getAllTimestamps(openedPortfolio)).map((ts) => new Date(ts)),
    identity,
  );
  if (allDates.length === 0) {
    // todo: VENN-27935 handle errors when there are no dates in a historical portfolio
    return null;
  }
  if (uniqBy(allDates, (date) => moment.utc(date).format('DD MMMM YYYY')).length !== allDates.length) {
    // todo: VENN-27935 handle errors when there are duplicate dates with different timestamps in a historical portfolio
    return null;
  }
  return (
    <InnerRoot openedPortfolio={openedPortfolio} allDates={allDates}>
      {children}
    </InnerRoot>
  );
};

const getAllTimestamps = (portfolio: Portfolio): number[] => {
  if (isEmpty(portfolio.children)) {
    return portfolio.closingAllocationsTs?.map((ts) => ts[0]) ?? [];
  }
  const currentTimestamps = portfolio.closingAllocationsTs?.map((ts) => ts[0]) ?? [];
  const rest = portfolio.children.flatMap(getAllTimestamps);
  return currentTimestamps.concat(rest);
};

const getAllErrors = (portfolio: Portfolio): HistoricalPortfolioErrorState => {
  const populateErrors = (portfolio: Portfolio, mutableErrors: HistoricalPortfolioErrorState) => {
    if (isEmpty(portfolio.children)) {
      // if this is a fund
      if (portfolio.fund) {
        // handle unmapped funds
        if (isNil(portfolio.fund.id)) {
          const closingAllocationsTs = portfolio.closingAllocationsTs ?? [];
          for (const ts of closingAllocationsTs) {
            if (mutableErrors[ts[0]]) {
              mutableErrors[ts[0]].push({ portfolioId: portfolio.id });
            } else {
              mutableErrors[ts[0]] = [{ portfolioId: portfolio.id }];
            }
          }
        }
      }
      // todo: VENN-27935 handle other errors in the future
      return;
    }
    for (const child of portfolio.children) {
      populateErrors(child, mutableErrors);
    }
  };
  const errors: HistoricalPortfolioErrorState = {};
  populateErrors(portfolio, errors);
  return errors;
};

type InnerRootProps = {
  openedPortfolio: Portfolio;
  allDates: Date[];
  children: (props: MultiHistoricalPortfolioContextValue) => React.ReactNode;
};
const InnerRoot = ({ openedPortfolio, allDates, children }: InnerRootProps) => {
  const [selectedDate, setSelectedDate] = useState<Date>(allDates[0]);
  useEffect(() => {
    setSelectedDate(allDates[0]);
  }, [allDates]);
  const errors = useMemo(() => getAllErrors(openedPortfolio), [openedPortfolio]);
  const context = useMemo(
    () => ({
      selectedDate,
      selectDate: (date: Date) => setSelectedDate(date),
      allDates,
      errors,
    }),
    [allDates, selectedDate, errors],
  );
  return (
    <MultiHistoricalPortfolioContext.Provider value={context}>
      {children(context)}
    </MultiHistoricalPortfolioContext.Provider>
  );
};
