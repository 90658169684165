import { isFunction } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { GetColor, Icon, Notifications, NotificationType, TOP_NAV_HEIGHT } from 'venn-ui-kit';
import {
  analyticsService,
  assertExhaustive,
  LibraryItemType,
  LibraryTab,
  navigateToLibrary,
  useModal,
} from 'venn-utils';
import type { UniversalUploaderContextValue } from '../../contexts/universal-uploader';
import UniversalUploaderContext from '../../contexts/universal-uploader';
import { IChooseActionView } from './constants';
import CreatePrivatePortfolioUniversalUploaderStep from './CreatePrivatePortfolioUniversalUploaderStep';
import SidePanelOverlay, { CLOSE_PANEL_CLASSNAME } from '../../side-panel-overlay/SidePanelOverlay';
import { InvestmentDataUploader } from './InvestmentDataUploader';
import { DataUploaderMode, type PortfolioMultiUploaderProps, type PortfolioMultiUploaderView } from './types';
import { ChooseActionView } from './views/ChooseActionView';
import { ChoosePortfolioActionView } from './views/ChoosePortfolioActionView';
import MultiPortfolioUploader from './views/MultiPortfolioUploader';
import PortfolioCreator from './views/PortfolioCreator';
import { DiscardUploadConfirmationModal } from './views/UploadConfirmationModals';
import { UNIVERSAL_UPLOADER_WIDTH } from './views/shared/layout';

const useAnalytics = (viewId: string) => {
  useEffect(() => {
    analyticsService.universalUploaderOpened();

    return () => {
      analyticsService.universalUploaderClosed();
    };
  }, []);

  useEffect(() => {
    analyticsService.universalUploaderViewChanged({ viewId });
  }, [viewId]);
};

/** Universal Uploader supporting bulk-portfolio upload  */
export const UniversalUploader = ({
  lastTimeBeforeUpdate,
  closeUploaderSlideout,
  onPrivatePortfolioCreated,
}: PortfolioMultiUploaderProps) => {
  const [view, setView] = React.useState<PortfolioMultiUploaderView>(IChooseActionView);

  useAnalytics(view.id);

  const history = useHistory();

  const onCompleteUploader = useCallback(
    (mode: DataUploaderMode, uploadedFundIds?: string[]) => {
      Notifications.notify('Upload Successfully Completed!', NotificationType.SUCCESS);
      closeUploaderSlideout();
      navigateToLibrary(history, {
        tab: mode === DataUploaderMode.Privates ? LibraryTab.PrivateAssets : LibraryTab.ReturnsData,
        selectedIds: uploadedFundIds ?? [],
        selectedFilters: {
          itemType: LibraryItemType.UPLOAD,
          quickFilters: [],
          tags: [],
        },
        lastTimeBeforeUpdate,
      });
    },
    [history, closeUploaderSlideout, lastTimeBeforeUpdate],
  );

  const [isConfirmationModalOpen, openConfirmationModal, closeConfirmationModal] = useModal();
  const onExit = useCallback(() => {
    if (view.id === 'REVIEW_PRO_FORMA_PORTFOLIO' || view.id === 'REVIEW_RETURNS' || view.id === 'REVIEW_PRIVATES') {
      openConfirmationModal();
    } else {
      closeUploaderSlideout();
    }
  }, [view, openConfirmationModal, closeUploaderSlideout]);
  const contextValue: UniversalUploaderContextValue = useMemo(() => {
    return {
      closeFlyout: onExit,
      setView,
      refreshedStyling: true,
    };
  }, [onExit]);

  const renderView = () => {
    switch (view.id) {
      case 'CHOOSE_ACTION':
        return <ChooseActionView setView={setView} />;
      case 'CHOOSE_ACTION_PORTFOLIO':
        return <ChoosePortfolioActionView setView={setView} />;
      case 'UPLOAD_RETURNS':
      case 'UPLOAD_PRIVATES':
      case 'REVIEW_PRIVATES':
      case 'REVIEW_RETURNS': // fallback to old uploader implementation
        return <InvestmentDataUploader viewId={view.id} setView={setView} onCompleteNavigate={onCompleteUploader} />;
      case 'UPLOAD_PORTFOLIO':
      case 'REVIEW_PRO_FORMA_PORTFOLIO':
      case 'PORTFOLIO_UPLOAD_CONFIRMATION':
      case 'REVIEW_HISTORICAL_PORTFOLIO':
        return <MultiPortfolioUploader viewId={view.id} />;
      case 'NEW_PORTFOLIO':
      case 'DUPLICATE_PORTFOLIO':
        return <PortfolioCreator mode={view.id} />;
      case 'CREATE_PRIVATE_PORTFOLIO':
        return (
          <CreatePrivatePortfolioUniversalUploaderStep
            onCancel={() => setView(IChooseActionView)}
            onPrivatePortfolioCreated={(portfolio) => {
              Notifications.notify('Portfolio Successfully Created!', NotificationType.SUCCESS);
              closeUploaderSlideout();
              onPrivatePortfolioCreated(portfolio);
            }}
          />
        );
      case 'CREATE_MASTER_PORTFOLIO':
        // There is no content for master portfolio step because it redirects to portfolio creation wizard
        return null;
      default:
        throw assertExhaustive(view.id);
    }
  };

  return (
    <StyledSidePanelOverlay
      margin={0}
      hideTopBackButton
      className="portfolio-multi-uploader"
      isOpen
      side="right"
      handleClose={onExit}
      width={UNIVERSAL_UPLOADER_WIDTH}
      noPadding
      scrollAlignRight
      useFocusTrap={false}
      closeOnEsc
      content={
        <UniversalUploaderContext.Provider value={contextValue}>
          <MainContainer>
            <HeaderContainer>
              <HeaderText>
                <HeaderTitle>{view.title}</HeaderTitle>
                {view.subtitle && (
                  <HeaderSubtitle>{isFunction(view.subtitle) ? view.subtitle() : view.subtitle}</HeaderSubtitle>
                )}
              </HeaderText>
              <button type="button" onClick={onExit} className="qa-close-panel">
                <Icon type="close" />
              </button>
            </HeaderContainer>
            {renderView()}
          </MainContainer>
          {isConfirmationModalOpen && (
            <DiscardUploadConfirmationModal
              closeEntireUploader
              onConfirm={() => {
                closeConfirmationModal();
                closeUploaderSlideout();
              }}
              onCancel={closeConfirmationModal}
            />
          )}
        </UniversalUploaderContext.Provider>
      }
    />
  );
};

const StyledSidePanelOverlay = styled(SidePanelOverlay)`
  .${CLOSE_PANEL_CLASSNAME} {
    color: ${GetColor.Black};
    .fas {
      font-weight: 300;
    }
  }
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  // top-level container for the universal uploader with fixed dimensions
  height: calc(100vh - ${TOP_NAV_HEIGHT}px);
  width: ${UNIVERSAL_UPLOADER_WIDTH}px;
  overflow: hidden;
`;

const HeaderText = styled.div`
  display: flex;
  flex-direction: column;
`;
const HeaderTitle = styled.div`
  font-style: normal;
  font-weight: 300;
`;
const HeaderSubtitle = styled.div`
  margin-top: 8px;
  font-size: 16px;
  font-weight: 400;
`;

const HeaderContainer = styled.div`
  display: flex;
  padding: 20px 24px;
  font-size: 32px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  border-bottom: 1px solid ${GetColor.GreyScale.Grey30};
  line-height: normal;
`;
