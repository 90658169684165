import type { ColDef, ICellRendererParams } from 'ag-grid-community';
import { compact } from 'lodash';
import React from 'react';
import type { PrivateFundPerformance } from 'venn-api';
import { Dates, FS } from 'venn-utils';
import { getCheckboxMenuHeaderRenderer } from '../../../archive-page/src/components/CheckboxMenuHeaderRenderer';
import {
  calledRenderer,
  dpiRenderer,
  fundSizeRenderer,
  getPrivatesActionsMenuRenderer,
  ItemNameRenderer,
  itemTypeRenderer,
  netIrrRenderer,
  netMultipleRenderer,
  rvpiRenderer,
} from './ColumnRenderers';
import { agGridCustomColumnsComparator } from './utils';
import isNil from 'lodash/isNil';

export const defaultPrivatesColumnStyling = {
  border: 'none',
  display: 'block',
  textAlign: 'right',
  paddingRight: 2,
};

export interface PrivatesTableRow {
  itemType: 'PORTFOLIO' | 'INVESTMENT';
  id: string;
  name: string;
  userUploaded: boolean;
  vintage?: number;
  status?: string;
  privateFundAssetClass?: string;
  strategy?: string;
  fundManager?: string;
  fundSize?: number;
  geographicFocus?: string;
  industries?: string;
  netIrrPct?: number;
  netMultiple?: number;
  rvpiPct?: number;
  dpiPct?: number;
  calledPct?: number;
  lastUpdated?: number;
  capitalCommitment?: number;
  ownerId?: number;
  ownerContextFriendlyName?: string;
}

export interface PrivateTableColumnsParams {
  updateData: () => void;
  clearSelectedItems: () => void;
  setSelectedRecoilState: (selectedIds: string[]) => void;
  manageDataView?: boolean;
}

export const getPrivatesTableColumns = ({
  updateData,
  clearSelectedItems,
  setSelectedRecoilState,
  manageDataView,
}: PrivateTableColumnsParams): ColDef<PrivatesTableRow>[] => {
  return compact([
    !manageDataView && {
      // Dummy column to show checkboxes and our custom header checkbox
      width: 30,
      minWidth: 30,
      headerComponent: getCheckboxMenuHeaderRenderer(setSelectedRecoilState),
      checkboxSelection: true,
      pinned: true,
    },
    !manageDataView && {
      headerName: 'Item Type',
      cellRenderer: itemTypeRenderer,
      width: 96,
      minWidth: 96,
      pinned: true,
      headerClass: 'ag-left-aligned-header',
      cellStyle: {
        ...defaultPrivatesColumnStyling,
        display: 'inline-flex',
        lineHeight: '16px',
      },
    },
    {
      colId: 'name',
      headerName: 'Name',
      field: 'name',
      cellRenderer: ItemNameRenderer,
      tooltipField: 'name',
      pinned: true,
      sortable: true,
      width: 325,
      minWidth: 325,
      headerClass: 'ag-left-aligned-header',
      cellStyle: {
        ...defaultPrivatesColumnStyling,
        textAlign: 'left',
        paddingRight: 10,
      },
    },
    FS.has('context_switching')
      ? {
          colId: 'ownerContextFriendlyName',
          headerName: 'Ownership',
          field: 'ownerContextFriendlyName',
          headerClass: 'ag-left-aligned-header',
          width: 120,
          minWidth: 120,
          flex: 1,
          sortable: false,
          cellStyle: {
            ...defaultPrivatesColumnStyling,
            textAlign: 'left',
            paddingLeft: 10,
          },
        }
      : null,
    {
      colId: 'vintageDate',
      headerName: 'Vintage',
      field: 'vintage',
      headerClass: 'ag-left-aligned-header',
      width: 78,
      minWidth: 78,
      flex: 1,
      sortable: true,
      valueFormatter: (item) => Dates.toYear(item.value),
      cellStyle: {
        ...defaultPrivatesColumnStyling,
        textAlign: 'left',
        paddingLeft: 10,
      },
    },
    {
      colId: 'status',
      headerName: 'Status',
      headerClass: 'ag-left-aligned-header',
      width: 110,
      minWidth: 110,
      flex: 1,
      sortable: true,
      comparator: agGridCustomColumnsComparator,
      cellStyle: {
        ...defaultPrivatesColumnStyling,
        textAlign: 'left',
      },
      field: 'status',
      tooltipField: 'status',
    },
    {
      colId: 'privateFundAssetClassName',
      headerName: 'Asset Class',
      headerClass: 'ag-left-aligned-header',
      width: 120,
      minWidth: 120,
      flex: 1,
      sortable: true,
      comparator: agGridCustomColumnsComparator,
      cellStyle: {
        ...defaultPrivatesColumnStyling,
        textAlign: 'left',
      },
      field: 'privateFundAssetClass',
      tooltipField: 'privateFundAssetClass',
    },
    {
      colId: 'strategyName',
      headerName: 'Strategy',
      headerClass: 'ag-left-aligned-header',
      width: 150,
      minWidth: 150,
      flex: 1,
      sortable: true,
      comparator: agGridCustomColumnsComparator,
      cellStyle: {
        ...defaultPrivatesColumnStyling,
        textAlign: 'left',
      },
      field: 'strategy',
      tooltipField: 'strategy',
    },
    {
      colId: 'manager',
      headerName: 'Fund Manager',
      headerClass: 'ag-left-aligned-header',
      width: 200,
      minWidth: 200,
      flex: 1,
      sortable: true,
      comparator: agGridCustomColumnsComparator,
      field: 'fundManager',
      tooltipField: 'fundManager',
      cellStyle: {
        ...defaultPrivatesColumnStyling,
        textAlign: 'left',
      },
    },
    {
      colId: 'size',
      headerName: 'Fund Size (USD)',
      field: 'fundSize',
      width: manageDataView ? 130 : 100,
      minWidth: manageDataView ? 130 : 100,
      flex: 1,
      sortable: true,
      comparator: agGridCustomColumnsComparator,
      cellRenderer: fundSizeRenderer,
      cellStyle: {
        ...defaultPrivatesColumnStyling,
        paddingRight: 10,
      },
    },
    {
      colId: 'geographicFocusName',
      headerName: 'Geo Focus',
      headerClass: 'ag-left-aligned-header',
      cellStyle: {
        ...defaultPrivatesColumnStyling,
        textAlign: 'left',
      },
      width: 116,
      minWidth: 116,
      flex: 1,
      sortable: true,
      comparator: agGridCustomColumnsComparator,
      field: 'geographicFocus',
      tooltipField: 'geographicFocus',
    },
    {
      headerName: 'Industries',
      width: 200,
      minWidth: 200,
      flex: 1,
      headerClass: 'ag-left-aligned-header',
      cellStyle: {
        ...defaultPrivatesColumnStyling,
        textAlign: 'left',
        width: 200,
      },
      field: 'industries',
      tooltipField: 'industries',
    },
    {
      colId: 'privateFundPerformance.netIrrPct',
      headerName: 'Net IRR',
      field: 'netIrrPct',
      width: 70,
      minWidth: 70,
      flex: 1,
      sortable: true,
      comparator: agGridCustomColumnsComparator,
      cellRenderer: netIrrRenderer,
    },
    {
      colId: 'privateFundPerformance.netMultiple',
      headerName: 'Net Multiple',
      field: 'netMultiple',
      width: manageDataView ? 100 : 86,
      minWidth: manageDataView ? 100 : 86,
      flex: 1,
      sortable: true,
      comparator: agGridCustomColumnsComparator,
      cellRenderer: netMultipleRenderer,
    },
    {
      colId: 'privateFundPerformance.rvpiPct',
      headerName: 'RVPI',
      field: 'rvpiPct',
      width: manageDataView ? 80 : 66,
      minWidth: manageDataView ? 80 : 66,
      flex: 1,
      sortable: true,
      comparator: agGridCustomColumnsComparator,
      cellRenderer: rvpiRenderer,
    },
    {
      colId: 'privateFundPerformance.dpiPct',
      headerName: 'DPI',
      field: 'dpiPct',
      width: manageDataView ? 80 : 66,
      minWidth: manageDataView ? 80 : 66,
      flex: 1,
      sortable: true,
      comparator: agGridCustomColumnsComparator,
      cellRenderer: dpiRenderer,
    },
    {
      colId: 'privateFundPerformance.calledPct',
      headerName: 'Called',
      field: 'calledPct',
      width: manageDataView ? 80 : 66,
      minWidth: manageDataView ? 80 : 66,
      flex: 1,
      sortable: true,
      comparator: agGridCustomColumnsComparator,
      cellRenderer: calledRenderer,
    },
    {
      colId: 'updated',
      headerName: 'Last Updated',
      field: 'lastUpdated',
      width: manageDataView ? 116 : 102,
      minWidth: manageDataView ? 116 : 102,
      flex: 1,
      sortable: true,
      valueFormatter: ({ data }) => {
        if (isNil(data?.lastUpdated)) {
          return '—';
        }
        return Dates.toDayMonthYear(data.lastUpdated);
      },
      comparator: agGridCustomColumnsComparator,
      cellStyle: {
        ...defaultPrivatesColumnStyling,
        paddingRight: 10,
      },
    },
    !manageDataView && {
      headerName: 'Actions',
      field: 'actions',
      cellRenderer: getPrivatesActionsMenuRenderer(updateData, clearSelectedItems),
      sortable: false,
      width: 52,
      minWidth: 52,
      maxWidth: 52,
      pinned: 'right' as const,
      cellStyle: {
        ...defaultPrivatesColumnStyling,
        textAlign: 'center',
      },
    },
  ]);
};

export const getPrivatesPerformanceTableColumns = () => {
  return compact([
    {
      headerName: 'Quarterly Period',
      field: 'asOfDate',
      flex: 1,
      cellRenderer: (item: ICellRendererParams<PrivateFundPerformance>) => (
        <>{item?.data?.asOfDate ? Dates.toQuarterYear(item?.data?.asOfDate) : '—'}</>
      ),
    },
    {
      headerName: 'Net IRR',
      field: 'netIrrPct',
      flex: 1,
      cellRenderer: netIrrRenderer,
    },
    {
      headerName: 'Net Multiple',
      field: 'netMultiple',
      flex: 1,
      cellRenderer: netMultipleRenderer,
    },
    {
      headerName: 'RVPI',
      field: 'rvpiPct',
      flex: 1,
      cellRenderer: rvpiRenderer,
    },
    {
      headerName: 'DPI',
      field: 'dpiPct',
      flex: 1,
      cellRenderer: dpiRenderer,
    },
    {
      headerName: 'Called',
      field: 'calledPct',
      flex: 1,
      cellRenderer: calledRenderer,
    },
  ]);
};

export const privatesTransactionsTableColumns: ColDef[] = [
  {
    headerName: 'Transaction Type',
    field: 'type',
    flex: 1,
    headerClass: 'ag-left-aligned-header',
    cellStyle: {
      textAlign: 'left',
      paddingLeft: 4,
    },
  },
  {
    headerName: 'Transaction Date',
    field: 'date',
    flex: 1,
  },
  {
    headerName: 'Transaction Amount',
    field: 'amount',
    flex: 1,
    cellStyle: {
      paddingRight: 4,
    },
  },
];
