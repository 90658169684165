import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import type { RangeType } from '../range-picker/RangePicker';
import RangePicker from '../range-picker/RangePicker';
import ButtonBase from '../../button/Button';
import TextField from '../../text-field/TextField';
import Icon from '../../icon/Icon';
import Calendar from '../calendar/Calendar';
import type { DatePickerProps } from '../types';
import { getTypeFromRange } from './logic';
import useNonOverlapping from './useNonOverlapping';
import { isNil } from 'lodash';

const DATE_FORMAT = 'D MMM YYYY';

interface DayPickerProps extends DatePickerProps {
  /**
   * Position of the header layout
   * If "right", the header will be aligned right (and vice-versa)
   */
  layout?: 'left' | 'right';
  disabled?: boolean;
  period?: RangeType;
}

const GRANULARITY = 'day';
const DayPicker = ({
  value,
  maxRange,
  layout,
  onApply,
  onUpdatePeriod,
  onUpdateDate,
  period,
  onCancel,
  options,
  disabled,
  hideActions = false,
  autoFocus = true,
  factorLens,
}: DayPickerProps) => {
  const fromInputRef = useRef<HTMLInputElement>(null);
  const [from, setFrom] = useState(value?.from);
  const [to, setTo] = useState(value?.to);

  const defaultPeriod = useMemo(() => {
    if (!isNil(value) && !Object.prototype.hasOwnProperty.call(value, 'period')) {
      return getTypeFromRange(GRANULARITY, { from, to, period: value?.period }, maxRange, 'DAILY', factorLens);
    }
    return undefined;
  }, [from, to, maxRange, value, factorLens]);

  const { isValid } = useNonOverlapping(maxRange, from, to);
  const parseManualDate = useCallback((manualString: string) => {
    // use strict parsing to prevent accidentally matching against wrong formats
    // see VENN-19553 for possible pitfalls
    const parsed = moment.utc(manualString, DATE_FORMAT, true);
    return parsed.isValid() && parsed.year() > 1900 ? parsed.valueOf() : null;
  }, []);

  const handleApply = useCallback(() => {
    onApply?.({ from, to, period });
  }, [from, to, period, onApply]);

  const handleFromChange = (newFrom: number) => {
    setFrom(newFrom);
    if (newFrom !== value?.from) {
      onUpdateDate?.({ to, from: newFrom });
    }
  };

  const handleToChange = (newTo: number) => {
    setTo(newTo);
    if (newTo !== value?.to) {
      onUpdateDate?.({ to: newTo, from });
    }
  };

  const handleManualFrom = (newValue: string) => {
    const newFrom = parseManualDate(newValue);
    if (newFrom) {
      handleFromChange(newFrom);
    }
  };

  const handleManualTo = (newValue: string) => {
    const newTo = parseManualDate(newValue);
    if (newTo) {
      handleToChange(newTo);
    }
  };

  // Focus the input on mount
  useEffect(() => {
    if (!autoFocus) {
      return;
    }
    fromInputRef.current?.focus();
    fromInputRef.current?.select();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update local state if props are changing
  useEffect(() => {
    setFrom(value?.from);
    setTo(value?.to);
  }, [value?.from, value?.to]);

  return (
    <Container>
      {maxRange && onUpdatePeriod && (
        <RangePicker
          range={maxRange}
          disposition={layout}
          value={period ?? defaultPeriod}
          onChange={onUpdatePeriod}
          options={options}
          granularity={GRANULARITY}
          maxFrequency="DAILY"
          factorLens={factorLens}
        />
      )}
      <Form>
        <Fields>
          <TextField
            aria-label="From Date"
            placeholder="From"
            className="qa-date-picker-from-input"
            value={moment.utc(from).format(DATE_FORMAT)}
            onChange={handleManualFrom}
            innerRef={fromInputRef}
            changeOnBlur
            center
          />
          <ArrowIcon type="arrow-right" />
          <TextField
            aria-label="To Date"
            placeholder="To"
            className="qa-date-picker-to-input"
            value={moment.utc(to).format(DATE_FORMAT)}
            onChange={handleManualTo}
            changeOnBlur
            center
          />
        </Fields>
        <Columns>
          <Column>
            <Calendar
              value={from}
              range={maxRange}
              onChange={handleFromChange}
              long
              className="qa-date-picker-from-calendar"
            />
          </Column>
          <Column>
            <Calendar
              value={to}
              range={maxRange}
              onChange={handleToChange}
              long
              className="qa-date-picker-to-calendar"
            />
          </Column>
        </Columns>
        {!hideActions && (
          <Actions>
            <Button
              type="submit"
              dense
              dominant
              onClick={handleApply}
              disabled={!(isValid || period) || disabled}
              tabIndex={0}
            >
              Apply
            </Button>
            <Button dense onClick={onCancel} tabIndex={0}>
              Cancel
            </Button>
          </Actions>
        )}
      </Form>
    </Container>
  );
};

const Container = styled.div`
  width: 480px;
`;

const Form = styled.div`
  margin: 20px;
`;

const Columns = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;
const Column = styled.div``;

const Fields = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`;

const Button = styled(ButtonBase)`
  margin-right: 0;
  margin-left: 10px;
`;

const Actions = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
`;

const ArrowIcon = styled(Icon)`
  font-size: 14px;
  margin: 0 25px;
`;

export default DayPicker;
