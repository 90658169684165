import React, { useContext } from 'react';
import { MultiPortfolioReviewContext } from '../common/MultiPortfolioReviewContext';
import type { Portfolio } from 'venn-api';
import { MultiHistoricalPortfolioContext } from './MultiHistoricalPortfolioContext';
import { compact, memoize } from 'lodash';

type ContentContainerProps = {
  children: (props: { portfolio: Portfolio }) => React.ReactNode;
};
export const ContentContainer = ({ children }: ContentContainerProps) => {
  const {
    data: { parsedResults },
    selectedIndexState: { selectedIndex },
  } = useContext(MultiPortfolioReviewContext);
  const portfolio = parsedResults[selectedIndex].parsedPortfolio;
  const { selectedDate } = useContext(MultiHistoricalPortfolioContext);
  const timestamp = selectedDate.valueOf();
  const filteredPortfolio = filterToDates(portfolio, timestamp);
  if (!filteredPortfolio) {
    return null;
  }
  const remappedAllocationsPortfolio = remapAllocations(filteredPortfolio, timestamp);

  return <>{children({ portfolio: remappedAllocationsPortfolio })}</>;
};

const remapAllocations = (portfolio: Portfolio, timestamp: number): Portfolio => {
  return {
    ...portfolio,
    allocation: sumAllocation(portfolio, timestamp),
    children: portfolio.children.map((childPortfolio) => remapAllocations(childPortfolio, timestamp)),
  };
};
const sumAllocation = (portfolio: Portfolio, timestamp: number): number => {
  const _sumAllocation = memoize((portfolio: Portfolio): number => {
    const fundAllocation = portfolio.closingAllocationsTs?.find(
      (closingAllocation) => closingAllocation[0] === timestamp,
    );
    const fundAllocationValue = fundAllocation ? fundAllocation[1] : 0;
    return (
      fundAllocationValue + portfolio.children.reduce((acc, childPortfolio) => acc + _sumAllocation(childPortfolio), 0)
    );
  });
  const result = _sumAllocation(portfolio);
  _sumAllocation.cache.clear?.();
  return result;
};
const filterToDates = (portfolio: Portfolio, timestamp: number): Portfolio | undefined => {
  if (containsDate(portfolio, timestamp)) {
    return {
      ...portfolio,
      children: compact(portfolio.children.filter((childPortfolio) => filterToDates(childPortfolio, timestamp))),
    };
  }
  return undefined;
};

const containsDate = (portfolio: Portfolio, timestamp: number): boolean => {
  const _containsDate = memoize((portfolio: Portfolio): boolean => {
    const fundContainsDate = !!portfolio.closingAllocationsTs?.some(
      (closingAllocation) => closingAllocation[0] === timestamp,
    );
    return fundContainsDate || portfolio.children.some(_containsDate);
  });
  const result = _containsDate(portfolio);
  _containsDate.cache.clear?.();
  return result;
};
